import React from 'react'
import Layout from '../components/layout'
import Seo from "../components/seo"
import UIUXBestPractices from '../docs/729_Solutions_CORNERSTONE_UI_UX_Best_Practices__Design_Principles.pdf'

const ThankYouDownloadUIUXBestPractices = ({location}) => {
    return (
        <Layout>
            <Seo 
              pathName={location.pathname} 
              title="Thank you" 
            />
            <section className="pt-6">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>Thank you!</h2>
                            <p>Download our 27 Top Best Practices for UI/UX Design PDF with the button below.</p>
                            <a href={UIUXBestPractices} className="btn btn-primary-light" target="_blank">Download our UI/UX Best Practices PDF</a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ThankYouDownloadUIUXBestPractices